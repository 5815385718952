import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import VueRouter from 'vue-router'
import {routes} from "@/routes";
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import "@/shared/css/site.scss"
import "@/css/bayhouse.scss"
import VueGtag from "vue-gtag";

Vue.use(Vuetify);

Vue.use(VueGtag, {
    config: {id: "G-33SN5LLMHN"}
});

export let vuetify = new Vuetify({
    options: {
        customProperties: true
    },
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: "#6EBFB7",
                secondary: "#8BD9DA",
                secondary_two: "#F4EFED",
                dark_brown: "#818B8C",
                black: "#020301",
                darken1: "#020301",
                darken2: "#818B8C"
            }
        }
    }
})
Vue.use(VueRouter)

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior(to, from, savedPosition) {
            // always scroll to top
            return {top: 0}
        }
    }
)
router.beforeEach(async (to, from, next) => {
        window.scrollTo(0, 0)
        next()
        window.scrollTo(0, 0)
    }
)
new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
