<template>
    <v-img
        :width="`${width}vw`"
        :max-height="`${height}vh`"
        :src="src"
        :position="position"
        alt="picture of hotel"
        @load="loaded($event)"
    >
        <slot name="default"></slot>
    </v-img>
</template>

<script>
export default {
    name: "FullImageSection",
    emits: ["loaded"],
    props: {
        src: {
            type: String,
            required: true
        },
        height: {
            type: Number,
            default: 90
        },
        width: {
            type: Number,
            default: 100
        },
        position: {
            type: String,
            default: "center center"
        }

    },
    data() {
        return {
            image: null
        }
    },
    methods: {
        loaded(val) {
            // console.log(`Img ${this.src} loaded ${val}`)
        }

    },
    async created() {
    }
}
</script>

<style scoped>

</style>