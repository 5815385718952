<template>
    <div>
        <v-row v-if="title">
            <v-col
                :cols="col" :offset="offset(col)"
                :sm="sm" :offset-sm="offset(sm)"
                :md="md" :offset-md="offset(md)"
                :lg="lg" :offset-lg="offset(lg)"
                :xl="xl" :offset-xl="offset(xl)"
                justify="center" align="center"
            >
                <h1 class="hedding"> {{ title }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                :cols="col" :offset="offset(col)"
                :sm="sm" :offset-sm="offset(sm)"
                :md="md" :offset-md="offset(md)"
                :lg="lg" :offset-lg="offset(lg)"
                :xl="xl" :offset-xl="offset(xl)"

            >
                <slot name="default" :item="text">
                    <p style="font-size: large">
                        {{ text }}
                    </p>
                </slot>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    name: "TheTextArea",
    props: {
        title: {type: String, default: ""},
        text: {type: [Array, String], default: ""},
        col: {
            type: Number,
            default: 10
        },
        sm: {
            type: Number,
            default: 10
        },
        md: {
            type: Number,
            default: 8
        },

        lg: {
            type: Number,
            default: 6
        },
        xl: {
            type: Number,
            default: 4
        },

    },
    methods: {
        offset(width) {
            if (width === 12) {
                return 0
            }
            if (width === 10) {
                return 1
            }
            if (width === 8) {
                return 2
            }
            if (width === 6) {
                return 3
            }
            if (width === 4) {
                return 4
            }
            if (width === 3) {
                return 6
            }
            if (width === 2) {
                return 5
            }

        }
    }

}
</script>

<style scoped>

</style>