<template>
    <div>
        <full-image-section :src="rooms.image" :height="60"/>
        <the-text-area
            class="mt-10"
            title="Rooms"
            :text="rooms.text"
        />

        <spacing :md="10" :lg="10" :xl="10">
            <text-image
                v-for="(room, index) in rooms.rooms"
                v-bind:key="index"
                :text_position="index % 2? 'left': 'right'"
            >
                <template #text>
                    <v-card
                        class="inde_flex_container"
                        flat
                        height="400px"
                    >
                        <v-card-title class="inde_flex_header text-h4">
                            {{ room.name }}
                        </v-card-title>

                        <v-card-text class="inde_flex_content text-h6">
                            {{ room.description }}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="inde_flex_footer mb-10">
                            <v-row>
                                <v-col>
                                    <v-btn
                                        :to="{name: 'Room', params:{room_name:room.link}}"
                                        text
                                    >
                                        Click here to see more!
                                        <v-icon small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </template>
                <template #image>
                    <v-card :img="room.image" height="400px" class="inde_flex_container"/>
                </template>
            </text-image>
        </spacing>
        <TheFooter class="mt-16"/>

    </div>
</template>

<script>
import {rooms_data} from "@/views/data/rooms";
import Spacing from "@/shared/components/helper/Spacing.vue";
import FullImageSection from "@/views/components/FullImageSection.vue";
import {image_mixin} from "@/mixins/image_mixin";
import TheTextArea from "@/views/components/TheTextArea.vue";
import TheFooter from "@/views/components/TheFooter";
import TheMap from "@/views/components/TheMap.vue";
import TextImage from "@/views/components/TextImage.vue";
import {Icon} from "@iconify/vue2";

export default {
    name: "Rooms",
    mixins: [image_mixin],
    components: {Icon, TextImage, TheMap, TheFooter, TheTextArea, FullImageSection, Spacing},
    data() {
        return {
            rooms: rooms_data
        }
    },
}
</script>

<style scoped>

</style>