import Vuex from 'vuex'
import Vue from 'vue'
import {auth_store} from "@/shared/store/auth";
import {dialog_store} from "@/shared/store/dialog_store";
import {create_persistent_storage} from "@/shared/store/auth";
import {variables_store} from "@/store/variables";

import "@/shared/css/site.scss"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: auth_store,
        dialog: dialog_store,
        variables: variables_store
    },
    plugins: [create_persistent_storage(['auth']).plugin]


})
