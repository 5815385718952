<template>
    <div>
        <v-row
            v-if="text_position_computed === 'right'"
            justify="center"
            align="center"
        >
            <v-col cols="12" md="6" align="center" justify="center">
                <slot name="image"></slot>
            </v-col>
            <v-col cols="12" md="6">
                <slot name="text"></slot>
            </v-col>
        </v-row>
        <v-row v-else-if="text_position === 'left'">
            <v-col cols="12" md="6">
                <slot name="text"></slot>
            </v-col>
            <v-col cols="12" md="6">
                <slot name="image"></slot>
            </v-col>
        </v-row>
        <v-row v-else>

        </v-row>
    </div>
</template>

<script>
import {image_mixin} from "@/mixins/image_mixin";

export default {
    name: "TextImage",
    mixins: [image_mixin],
    props: {
        text_position: {
            type: String,
            default: "left"
        },
    },
    computed: {
        text_position_computed() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return "right"
            }
            return this.text_position
        }
    }
}
</script>

<style scoped>

</style>