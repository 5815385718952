<template>
    <div>
        <p class="one_line text-subtitle-2">
            <strong>General inquires </strong>
            <span>| {{ contact_data.details.email_gm }}</span>
        </p><br>
        <p class="one_line text-subtitle-2">
            <strong>Booking inquires </strong>
            <span>| {{ contact_data.details.email_booking }} </span>
        </p><br/>
        <p class="one_line text-subtitle-2">
            <strong>Events inquires </strong>
            <span>| {{ contact_data.details.email_events }} </span>
        </p><br/>
        <p class="one_line text-subtitle-2">
            <strong>Phone </strong>
            <span>| {{ contact_data.details.phone }}</span>
        </p><br/>
        <p class="one_line text-subtitle-2">
            <strong>W/App </strong>
            <span>| {{ contact_data.details.whats_app }}</span>
        </p>
    </div>
</template>

<script>
import {contact_data} from "@/views/data/contact";
import {image_mixin} from "@/mixins/image_mixin";

export default {
    name: "contact_details",
    components: {},
    mixins: [image_mixin],
    data() {
        return {
            contact_data
        }
    }
}
</script>

<style scoped>
.one_line {
    display: inline;
}
</style>