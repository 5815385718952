export let image_mixin = {
    methods: {
        req(image) {
            try {
                return require(`@/assets/img/${image}?vuetify-preload`)
            } catch (e) {
                console.log("Missing img", image)
            }
            return ""
        },
        path(image) {
            return `/img/${image}`
        }
    }
}