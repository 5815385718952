<template>
    <section id="contact" class="map" :style="{height: `${height}`}">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d127227.09811991667!2d-61.73962725451983!3d12.113843680692991!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa102fbe53f2c077!2sThe%20Bay%20House!5e0!3m2!1sen!2sus!4v1580208073532!5m2!1sen!2sus"
            width="100%" height="100%"
        ></iframe>
        <br/>
        <small>
            <a href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=Twitter,+Inc.,+Market+Street,+San+Francisco,+CA&amp;aq=0&amp;oq=twitter&amp;sll=28.659344,-81.187888&amp;sspn=0.128789,0.264187&amp;ie=UTF8&amp;hq=Twitter,+Inc.,+Market+Street,+San+Francisco,+CA&amp;t=m&amp;z=30&amp;iwloc=A"></a>
        </small>
    </section>
</template>

<script>
export default {
    name: "TheMap",
    props: {
        height: {
            type: Number,
            default: 30,
        }
    }
}
</script>

<style scoped>
/* Map */
.map {
    height: 30rem;
}


</style>