import {image_mixin} from "@/shared/mixins/image_mixin";


export let event_data = {
    image: image_mixin.methods.req("hotel/balcony.jpeg"),
    sub_pages: [
        {
            name: "Day Pass",
            url: "/events/daypass",
        },
        {
            name: "Wedding",
            url: "/events/wedding"
        },
        {
            name: "Party",
            url: "/events/party"
        },
    ],
    images: {
        first: image_mixin.methods.req("events/wedding.jpeg"),
        second: image_mixin.methods.req("events/food_balcony.jpeg")
    },
    texts: [
        {
            image_position: "center center",
            image: image_mixin.methods.req("events/food_balcony.jpeg"),
            title: "Events",
            text: `
<p style="font-size: medium;" >
Bay House offers an exquisite event space, where unforgettable moments come to life against the breathtaking backdrop of Grand Anse beach. 
Nestled in a prime location, our unique venue showcases panoramic views from both the Upper Sun Deck and the inviting pool deck,
ensuring a picturesque setting for intimate gatherings or grand-scale events. Whether you envision a romantic wedding ceremony,
a lively staff party, an elegant dinner soiree, a joyous birthday celebration, or an engaging seminar,
our in-house event planner is dedicated to ensuring every detail is meticulously crafted.
With our stunning views and professional guidance, your event at our space will be an unforgettable experience for you and your guests.
</p>
`
        },
        {
            image: image_mixin.methods.req("new/pool11.jpg"),
            image_position: "center center",
            title: "Day Passes",
            text: `
<p style="font-size: medium;" >

Bay House offers an exquisite event space, where unforgettable moments come to life against the breathtaking backdrop of Grand Anse beach. <br>
Nestled in a prime location, our unique venue showcases panoramic views from both the Upper Sun Deck and the inviting pool deck,
ensuring a picturesque setting for intimate gatherings or grand-scale events. Whether you envision a romantic wedding ceremony,
a lively staff party, an elegant dinner soiree, a joyous birthday celebration, or an engaging seminar,
our in-house event planner is dedicated to ensuring every detail is meticulously crafted.<br>
With our stunning views and professional guidance, your event at our space will be an unforgettable experience for you and your guests.

</p>

`
        },
        {
            image: image_mixin.methods.req("events/wedding.jpeg"),
            image_position: "bottom center",
            title: "Weddings at Bay House - An Unforgettable Celebration",
            text: `
<p style="font-size: medium;">
Imagine your special day at Bay House, where dreams become reality against the backdrop of the Caribbean Sea.
Our majestic terrace, splendid balcony, and regal porch offer the perfect settings for your dream wedding. <br>
Say "I do" with the azure waters and the golden sands of Grand Anse Beach as your witness.
At Bay House, we understand the importance of your wedding day, and we're committed to making it truly exceptional.
Our luxurious patio and elegant deck provide versatile spaces for ceremonies, receptions, and everything in between. <br>
Our opulent living spaces, lavish lounges, and palatial parlors offer the perfect ambiance for your pre-wedding preparations, ensuring you feel pampered and relaxed on your big day.
Our dedicated team will work closely with you to bring your vision to life, whether you desire a romantic beachfront ceremony or a grand, elegant affair in our exquisite family rooms.
With 15 different room types, you and your guests can experience the utmost comfort and luxury.
Make your wedding day an unforgettable Caribbean experience at Bay House, where historic charm meets modern luxury, and love stories are celebrated with style and grace.
</p>
`
        }
    ],
}