import {image_mixin} from "@/mixins/image_mixin";
import {rooms_data} from "@/views/data/rooms";
import {event_data} from "@/views/data/event";

export let links = {
    logo_white: image_mixin.methods.req("logo/logo_new.png"),
    logo_black: image_mixin.methods.req("logo/logo_new.png"),
    home: {
        name: "home", url: "/"
    },
    links: [
        {
            name: "Rooms & Suites",
            dropdown: [
                {
                    name: "All rooms",
                    url: "/rooms",
                },
                ...rooms_data.rooms.map(function (x) {
                        return {
                            url: `/rooms/${x.link}`,
                            name: x.name,

                        }
                    }
                )
            ]
        },
        {
            name: "Events",
            url: "/events",
            // dropdown: event_data.sub_pages
        },
        {
            name: "About Us", url: "/about"
        },
        {
            name: "Contact Us", url: "/contact"
        },
        {
            name: "Book Now", url: "/booking"
        }
    ]
}
