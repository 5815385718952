<template>
    <div>
        <v-app-bar
            app
            elevation="0"
            fixed
            min-height="80"
            ref="app_bar"
            :height="computed_height + 40"
        >
            <router-link :to="links.home.url">
                <v-row align="center">
                    <v-img
                        :src="req('logo/logo_with_text_below.svg')"
                        :height="computed_height"
                        max-width="200px"
                        contain
                    ></v-img>
                </v-row>
            </router-link>
            <v-spacer/>

            <template v-for="link in links.links" v-if="!$vuetify.breakpoint.smAndDown">
                <v-menu
                    v-if="link.dropdown"
                    v-model="link.dropdown_open"
                    offset-y

                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            x-large
                            class="mx-1"

                        >
                            {{ link.name }}
                            <v-icon
                                v-text="'mdi-chevron-down'"
                                :small="$vuetify.breakpoint.mdAndDown"
                            />
                        </v-btn>

                    </template>
                    <v-list
                        dense
                        style="padding: 0"
                    >
                        <div
                            v-for="(item, index) in link.dropdown"
                            :key="index"
                        >

                            <v-list-item
                                class="font-weight-regular text-capitalize"
                                :to="item.url"
                            >
                                <v-list-item-title>
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
                <v-btn
                    v-else
                    :to="link.url"
                    :href="link.href"
                    text
                    :x-large="$vuetify.breakpoint.lgAndUp"
                    class="mx-1"

                >
                    {{ link.name }}
                </v-btn>

            </template>

            <v-spacer/>
            <v-app-bar-nav-icon @click="drawer = drawer === false" v-if="$vuetify.breakpoint.smAndDown" style="padding-right: 50px"/>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed right v-if="$vuetify.breakpoint.smAndDown">
            <v-list-item-group>
                <div v-for="link in links.links">
                    <v-list-item
                        @click="drawer = false"
                        :href="link.href"
                        v-if="link.href"
                    >
                        <v-list-item-icon v-if="link.icon">
                            <v-icon v-text="link.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ link.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        @click="drawer = false"
                        v-else-if="link.url"
                        :to="link.url"
                    >
                        <v-list-item-icon v-if="link.icon">
                            <v-icon v-text="link.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ link.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-navigation-drawer>
    </div>
</template>

<script>


import {image_mixin} from "@/mixins/image_mixin";
import {links} from "@/views/data/links";
import IndeImg from "@/views/components/IndeImg.vue";

export default {
    name: "Navbar",
    components: {IndeImg},
    mixins: [image_mixin],
    props: {},
    data() {
        return {
            scroll: 0,
            drawer: false,
            links,

        }
    },
    watch: {
        "$refs.app_bar.computedHeight": {
            handler(val) {

            }
        }
    },
    mounted() {
        let tmp = this.$refs?.app_bar?.computedHeight
        if (tmp) {
            return tmp
        }
        return null
    },
    methods: {
        check() {
            let tmp = this.$refs?.app_bar?.computedHeight
            if (tmp) {
                return tmp
            }
            return 100
        }
    },
    computed: {
        scroll_limit() {
            return this.scroll > 500
        },
        computed_height() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 50
            }
            let diff = 0.6
            let scroll_limit = 50
            let start_size = 90
            if (this.scroll > scroll_limit) {
                return start_size - (diff * scroll_limit)
            }
            return start_size - (this.scroll * diff)

        }

    },
    created() {
        this.scroll = window.scrollY
        addEventListener('scroll', (event) => {
            this.scroll = window.scrollY
        });

    }
}
</script>

<style scoped>


#logo_text {
    font-family: "Playfair Display", sans-serif;
    font-size: 30px;
    font-weight: bold;
}
</style>

