<template>
    <v-btn
        icon
        href="https://www.instagram.com/bayhousegrenada/"
        target="_blank"
    >
        <v-icon size="50">
            mdi-instagram
        </v-icon>
    </v-btn>
</template>
<script>
export default {
    name: "instagram"
}
</script>