<template>
    <v-btn
        icon
        href="https://www.linkedin.com/company/bay-house-grenada/"
        target="_blank"
    >
        <v-icon size="50">
            mdi-linkedin
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "linkedin"
}
</script>