export let image_mixin = {
    methods: {
        req(image) {
            try {
                return require(`@/assets/img/${image}`)
            } catch (e) {
                console.log("Missing img", image)

            }
        },
        path(image) {
            return `/img/${image}`
        }
    }
}