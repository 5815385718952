<template>
    <v-img
        class="carousel_image"
        :src="src"
        :alt="alt"
        :position="position"
        :max-height="`${max_height}vh`"
        :max-width="`${max_width ? max_width : 50}vw`"
        :contain="contain"
        @click="$emit('click', true)"
    />
</template>
<script>
export default {
    name: 'inde-img',
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: "alt missing"
        },
        max_height: {
            type: Number,
            required: true
        },
        max_width: {
            type: Number
        },
        position: {
            type: String,
            default: "center center"
        },
        contain: {
            type: Boolean,
            default: true
        }
    }
}
</script>
<style scoped>

.carousel_image {
    margin-right: 15px;
}

</style>