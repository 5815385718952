import {image_mixin} from "@/mixins/image_mixin";

export let contact_data = {
    image: image_mixin.methods.req("new/pool_sea_view.jpg"),
    secondary_image: image_mixin.methods.req("new/reception.png"),
    texts: {
        first: `
    Please contact us regarding room availability & rates or if you would like to host an event at Bay House.
    `
    },
    details: {
        email_booking: "booking@bayhousegrenada.com",
        email_gm: "gm@bayhousegrenada.com",
        email_events: "events@bayhousegrenada.com",
        phone: "+1 (473) 439-0888",
        whats_app: "+1 (473) 456-0088",
    }
}