<template>
    <v-col
        :cols="internal_xs" :offset="offset(internal_xs)"
        :sm="internal_sm" :offset-sm="offset(internal_sm)"
        :md="internal_md" :offset-md="offset(internal_md)"
        :lg="internal_lg" :offset-lg="offset(internal_lg)"
        :xl="internal_xl" :offset-xl="offset(internal_xl)"
    >
        <slot name="default"></slot>
    </v-col>
</template>

<script>
export default {
    name: "Spacing",
    props: {
        col: {
            type: Number,
            default: 12,
        },
        cols: {
            type: Number,
            default: 12,
        },
        sm: {
            type: Number,
            default: null
        },
        md: {
            type: Number,
            default: null
        },

        lg: {
            type: Number,
            default: null
        },
        xl: {
            type: Number,
            default: null
        },

    },
    data() {
        return {
            internal_xs: null,
            internal_sm: null,
            internal_md: null,
            internal_lg: null,
            internal_xl: null,
        }
    }
    ,
    methods: {
        offset(width) {
            if (width === 12) {
                return 0
            }
            if (width === 10) {
                return 1
            }
            if (width === 8) {
                return 2
            }
            if (width === 6) {
                return 3
            }
            if (width === 4) {
                return 4
            }
            if (width === 3) {
                return 6
            }
            if (width === 2) {
                return 5
            }
            console.log(`(bug) check width ${width} ${this.internal_xs}`)
        }
    },
    created() {

        if (this.cols) {
            this.internal_xs = this.cols
            this.internal_sm = this.cols
            this.internal_md = this.cols
            this.internal_lg = this.cols
            this.internal_xl = this.cols
        } else if (this.col) {
            this.internal_xs = this.col
            this.internal_sm = this.col
            this.internal_md = this.col
            this.internal_lg = this.col
            this.internal_xl = this.col
        } else {
            throw `Missing cols ${this.col}`
        }
        if (this.sm != null) {
            this.internal_sm = this.sm
            this.internal_md = this.sm
            this.internal_lg = this.sm
            this.internal_xl = this.sm
        }
        if (this.md != null) {
            this.internal_md = this.md
            this.internal_lg = this.md
            this.internal_xl = this.md
        }
        if (this.lg != null) {
            this.internal_lg = this.lg
            this.internal_xl = this.lg
        }
        if (this.xl != null) {
            this.internal_xl = this.xl
        }

    }
}
</script>

<style scoped>

</style>